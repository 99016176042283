<template>
  <div class="shareDetailHeader"
       v-if="vueStyle=='one'"
       :style="{transform: `translate(${translateLeft}, 0px)`,width:width,
       'background-color':color == 'none' ? 'rgba(0, 0, 0, 0)' : color}"
  >
    <div v-if="showOrg" class="orgView" @click="selectOrg">
      <div class="orgTitle" :style="{'color':textColor}">
        {{ orgName }}
      </div>
      <div class="triangle" :style="{
      border:`4px solid ${color == 'none' ? 'white' : color}`,
      'border-top-color': `${textColor}`
      }"
      ></div>
    </div>
    <div v-if="showTitle" class="titleView" :style="{'color':textColor}">
      {{ title }}
    </div>

    <div class="searchView" v-if="showSearch"
         :style="{width: useWidth}"
    >
      <el-input
          size="medium"
          class="search"
          prefix-icon="el-icon-search"
          placeholder="请输入内容"
          v-model="inPut"
      ></el-input>
      <div class="actionButton" @click="getHeaderQuery">搜索</div>
      <div style="clear: both"></div>
    </div>

    <div v-if="showDate" class="dateContent">
      <el-date-picker
          :clearable="false"
          size="mini"
          :class="{whiteDateView:textColor=='white', dateView:true}"
          v-model="timeStamp"
          type="date"
          @change="getHeaderQuery"
          placeholder="选择日期"
      >
      </el-date-picker>
      <div class="triangle" :style="{
      border:`4px solid ${color == 'none' ? 'white' : color}`,
      'border-top-color': `${textColor}`
      }"
      ></div>
    </div>

    <div v-if="showAdd" class="addIcon">
      <svg-icon width="30px" height="30px" name="add" @click="addAction"></svg-icon>
    </div>
  </div>
  <div v-else
       class="shareDetailHeader"
       :style="{transform: `translate(${translateLeft}, 0px)`,width:width,
       'background-color':color == 'none' ? 'rgba(0, 0, 0, 0)' : color}"
  >
    <div class="searchView"
         :style="{width: styleTwoSearchWidth}"
    >
      <el-input
          size="medium"
          class="search"
          prefix-icon="el-icon-search"
          placeholder="请输入内容"
          v-model="inPut"
      ></el-input>
      <div class="actionButton" @click="getHeaderQuery">搜索</div>
      <div style="clear: both"></div>
    </div>
    <div class="addIcon">
      <svg-icon width="30px" height="30px" name="add" @click="addAction"></svg-icon>
    </div>

    <div :style="{width: '100%',height: $changePxToRem('30px')}">
      <div class="orgView" @click="selectOrg" style="justify-content: flex-start">
        <div class="orgTitle" :style="{'color':textColor}">
          {{ orgName }}
        </div>
        <div class="triangle" :style="{
      border:`4px solid transparent`,
      'border-top-color': 'black'
      }"
        ></div>
      </div>

      <div class="dateContent dateContentLeft">
        <el-date-picker
            :clearable="false"
            size="mini"
            :class="{dateView:true}"
            v-model="timeStamp"
            type="date"
            @change="getHeaderQuery"
            placeholder="选择日期"
        >
        </el-date-picker>
        <div class="triangle" :style="{
      border:`4px solid transparent`,
      'border-top-color': 'black'
      }"
        ></div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'shareDetailHeader',
  props: {
    vueStyle: {
      default: 'one'
    },
    textColor: {
      default: 'black'
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showAdd: {
      type: Boolean,
      default: false
    },
    translateLeft: {
      type: String,
      default: '0px'
    },
    width: {
      type: String,
      default: '100%'
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    showOrg: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    title: {
      default: ''
    },
    color: {
      default: 'white'
    },
    orgList: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectOrgList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    styleTwoSearchWidth() {
      let width = 30
      let widthStr = this.$changePxToRem(width + 'px')
      return `calc(100% - ${widthStr})`
    },
    useWidth() {
      let width = 0

      if (this.showOrg) {
        width = width + 100
      }

      if (this.showDate) {
        width = width + 100
      }

      if (this.showAdd) {
        width = width + 30
      }
      let widthStr = this.$changePxToRem(width + 'px')
      return `calc(100% - ${widthStr})`
    },
    orgName() {
      let name = ''
      for (let i = 0; i < this.selectOrgList.length; i++) {
        let item = this.selectOrgList[i]
        name = name + (name == '' ? '' : '/') + item.name
      }
      if (name == '') {
        name = '全部科室'
      }
      return name
    }
  },
  data() {
    return {
      timeStamp: new Date().getTime(),
      inPut: ''
    }
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    addAction: function() {
      this.$emit('addAction', {})
    },
    updateTime: function(startTime) {
      this.timeStamp = new Date(startTime).getTime()
    },
    selectOrg: function() {
      this.$emit('selectOrg', {})
    },
    returnQuery: function() {
      let tenantIds = () => {
        let name = ''
        for (let i = 0; i < this.selectOrgList.length; i++) {
          let item = this.selectOrgList[i]
          name = name + (name == '' ? '' : ',') + item.id
        }
        return name
      }
      let query = {
        tenantIds: tenantIds(),
        isTenantIdChild: 1
      }
      if (this.showDate) {
        let timeType = 1
        let timeStr = this.formatDayDate(this.timeStamp)
        let startTime = timeStr + ' 00:00:00'
        let endTime = timeStr + ' 23:59:59'
        query['timeType'] = timeType
        query['startTime'] = startTime
        query['endTime'] = endTime
        query['timeStamp'] = this.timeStamp
      } else {
        let timeType = 1
        let timeStr = this.formatDayDate(new Date().getTime())
        let startTime = '2020-01-01 00:00:00'
        let endTime = timeStr + ' 23:59:59'
        query['timeType'] = timeType
        query['startTime'] = startTime
        query['endTime'] = endTime
        query['timeStamp'] = this.timeStamp
      }
      return query
    },
    getHeaderQuery: function() {
      this.$emit('headQuery', this.returnQuery())
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.shareDetailHeader {
  height: 40px;

  .orgView {
    margin-left: 5px;
    width: 90px;
    text-align: right;
    height: 100%;
    display: flex;
    justify-content: center;
    float: left;
    align-items: center;
    margin-right: 10px;

    .orgTitle {
      max-width: calc(100% - 10px);
      height: 40px;
      line-height: 40px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: break-spaces;
      text-align: left;
    }

    .triangle {
      margin-left: 3px;
      width: 1px;
      height: 1px;
      //border: 4px solid $color2;
      //border-top-color: $color2;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      transform: translate(0px, 25%);
    }

  }

  .titleView {
    width: calc(100% - 210px);
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    float: left;
  }

  .searchView {
    float: left;
    height: 100%;

    .search {
      margin-right: 10px;
      width: calc(100% - 10px);
      position: relative;
      top: 50%;
      transform: translate(0px, -50%);
      float: left;
    }


    .actionButton {
      float: left;
      background-image: linear-gradient(to left, $color_primary, $color_primary, #4ca6f6, #cfe8ff);
      //background-color: $color_primary;
      width: 70px;
      height: 36px;
      position: relative;
      left: calc(100% - 80px);
      transform: translate(0, calc(-100% + 2px));
      border-radius: 17px;
      line-height: 36px;
      text-align: center;
      color: white;
    }

  }

  .dateContentLeft {
    margin-left: 40px;
  }


  .dateContent {
    width: 90px;
    height: 100%;
    top: 50%;
    position: relative;
    transform: translate(0px, -50%);
    float: left;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dateView {
      width: calc(100% - 10px)
    }

    .whiteDateView {

    }

    .triangle {
      width: 1px;
      height: 1px;
      border: 4px solid $color2;
      border-top-color: $color2;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      transform: translate(0px, 25%);
    }
  }

  .addIcon {
    width: 30px;
    height: 30px;
    float: right;
    position: relative;
    top: 50%;
    transform: translate(0px, -50%);
  }

}

::v-deep .dateView .el-input__prefix {
  display: none;
}

::v-deep .el-input--prefix .el-input__inner {
  padding-left: 30px;
  padding-right: 5px;
}

::v-deep .dateView .el-input__inner {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

::v-deep .whiteDateView .el-input__inner {
  color: white;
}


::v-deep .search .el-input__inner {
  border-radius: 20px;
}

::v-deep .dateContent .el-input__inner {
  padding-left: 0px;
}


</style>
