import { vm } from '@/main'

export const detailHeader = {
  computed: {
    dialogTitle: function() {
      return '科室'
    },

    dataList: function() {
      let list = this.orgList
      return list
    },
    defaultCheckedKeys: function() {
      let list = []
      let selectList = this.selectOrgList
      for (let i = 0; i < selectList.length; i++) {
        let asset = selectList[i]
        if (list.indexOf(asset.id) >= 0) {
          continue
        }
        list.push(asset.id)
      }
      return list
    }
  },
  data() {
    return {
      query: {},
      dialogVisible: false,
      orgList: [],
      selectOrgList: []
    }
  },
  methods: {
    addAction: function(res) {
      this.$push('workManager/workShareSetUp')
    },
    updateTenantIds: function(tenantIds) {
      let findName = (id, list) => {
        if (list.length == 0) {
          return '-'
        }

        let children = []
        for (let i = 0; i < list.length; i++) {
          let data = list[i]
          if (data.id == id) {
            return data.name
          }
          if (this.$valueIsExist(data, ['children']) == false) {
            continue
          }
          children = children.concat(data['children'])
        }

        return findName(id, children
        )
      }

      if (this.orgList.length == 0) {
        return
      }
      let list = tenantIds.split(',')
      let select = []
      for (let i = 0; i < list.length; i++) {
        let id = list[i]
        select.push({
          id: list[i],
          name: findName(id, this.orgList)
        })
      }
      this.selectOrgList = select
    },
    dialogSure: function(res) {
      this.selectOrgList = res.nodes
      this.dialogVisible = false
      this.$nextTick(() => {
        this.query = this.$refs['shareDetailHeader'].returnQuery()
      })
    },
    headQuery: function(res) {
      this.query = res
    },
    dialogClose: function(res) {
      this.dialogVisible = res.dialogVisible
    },
    getAllData() {
      this.getOrg().then(res => {
        this.orgList = res.list
        this.selectOrgList = res.selectList
        this.$nextTick(() => {
          this.query = this.$refs['shareDetailHeader'].returnQuery()
        })
      })
    },
    // 获取子机构科室
    getOrg: function() {
      return new Promise(resolve => {
        this.orgList = []

        let orgInfo = this.$store.getters.orgInfo
        if (orgInfo.length > 0) {
          let selectList = []
          let userInfo = this.$store.getters.userInfo
          let tenantId = userInfo.tenantId
          for (let i = 0; i < orgInfo.length; i++) {
            let org = orgInfo[i]
            if (org.id == tenantId) {
              selectList.push(org)
              break
            }
          }
          resolve({ list: orgInfo, selectList: selectList })
          return;
        }



        this.$api.userInfo.getListTreeTenantAuthority({}).then(res => {
          let jsonStr = JSON.stringify(res.data)
          jsonStr = jsonStr.replace(/title/g, 'name')
          jsonStr = jsonStr.replace(/child/g, 'children')

          let list = []
          let selectList = []

          try {
            list = JSON.parse(jsonStr)
            let userInfo = this.$store.getters.userInfo
            let tenantId = userInfo.tenantId
            for (let i = 0; i < list.length; i++) {
              let org = list[i]
              if (org.id == tenantId) {
                selectList.push(org)
                break
              }
            }
          } catch (e) {

          }
          this.$store.commit('setOrgInfo', list)
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    }
  }
}
