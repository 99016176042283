<template>
  <div class="deviceShareDetail">
    <share-detail-header v-show="showHeader" ref="shareDetailHeader"
                         :text-color="textColor"
                         :title="title"
                         :color="color"
                         @headQuery="headQuery"
                         @selectOrg="dialogClose({dialogVisible:true})"
                         :select-org-list="selectOrgList"
    ></share-detail-header>
    <router-view :query="query" :select-org-list="selectOrgList"></router-view>
    <vue-tree
        ref="vueTree"
        @dialogClose="dialogClose"
        @dialogSure="dialogSure"
        :expanded-keys="defaultCheckedKeys"
        :default-checked-keys="defaultCheckedKeys"
        :dialog-title="dialogTitle"
        :data-list="dataList"
        :dialog-visible="dialogVisible"
    ></vue-tree>
  </div>
</template>

<script>
import ShareDetailHeader from '@/views/shareAndDispatch/device/deviceShareDetail/shareDetail/shareDetailHeader'
import VueTree from '@/components/tree/vueTree'
import {detailHeader} from '@/views/shareAndDispatch/device/deviceShareDetail/shareDetail/detailHeaderMixin'

export default {
  name: 'deviceShareDetail',
  components: { VueTree, ShareDetailHeader },
  mixins:[detailHeader],
  data() {
    return {
      textColor:'white',
      color:'#0279E1',
      title:'',
      barIndex: 0,
      barList: [
        {
          svg: '共享池',
          name: '共享池',
          url: 'deviceShareDetail/shareDetail',
          query: {}
        }, {
          svg: '维修工单',
          name: '维修工单',
          url: 'deviceShareDetail/repairDetail',
          query: {}
        }, {
          svg: 'I',
          name: '我的',
          url: 'deviceShareDetail/deviceMine',
          query: {}
        }]
    }
  },
  computed: {
    showHeader() {
     return !( this.$route.name == 'deviceShareDetail_deviceMine' || this.$route.name == 'deviceShareDetail_shareDetail')
    },
  },
  beforeRouteUpdate(to,form,next){
    if (to.name == 'deviceShareDetail_shareDetail') {
      this.textColor = 'white'
      this.color = '#0279E1'
      this.title = ''
    }
    if (form.name == 'deviceShareDetail_shareDetail') {
      this.color = '#eff1f4'
      this.title = ''
      this.textColor = 'black'


    }
    next()
  },
  beforeDestroy() {
  },
  mounted() {
    this.$nextTick(() => {
      this.$bus.$emit('showBottomStatus', { list: this.barList, index: 0 })
      this.getAllData()
    })
  },
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceShareDetail {
  width: 100%;
  height: 100%;
}

</style>
